<template>
     <v-app-bar app color="primary">
         <v-app-bar-nav-icon @click="$router.push({name: 'home'})">
       <template slot="default">
           <v-btn icon color="white" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
         </v-app-bar-nav-icon>
        <v-spacer/>
         <div class="text-title-1 natural--text text-no-wrap">{{title}}</div> 
    <v-btn icon></v-btn>
   <v-spacer/>
          <template v-slot:extension>
               <v-tabs fixed-tabs background-color="white">
                   <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab to="/recorddaily/waiting">รอการส่ง</v-tab>
          <v-tab to="/recorddaily/savedraft">บันทึกแบบร่าง</v-tab>
          <v-tab to="/recorddaily/done">ส่งแล้ว</v-tab>
        </v-tabs>

        </template>
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
  },

}
</script>

<style>

</style>